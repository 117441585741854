import React from "react"
import Layout from "../../Layout"
import Meta from "../../components/Meta"
import Header from "../../components/Header"
import Footer from "../../components/Footer"
import Spacer from "../../components/Spacer"
import StaticHeadline from "../../components/static/StaticHeadline"
import StaticParagraph from "../../components/static/StaticParagraph"
import StaticContainer from "../../components/static/StaticContainer"
import StaticImage from "../../components/static/StaticImage"
import StaticUl from "../../components/static/StaticUl"
import StaticOl from "../../components/static/StaticOl"
import usePagesImages from "../../hooks/usePagesImages"

export default function BenzinpreisRechner({ data }) {
  const fluid = usePagesImages("benzinpreis-rechner.jpg")

  return (
    <Layout>
      <Meta
        title="Benzinpreis-Rechner: Spritverbrauch berechnen"
        description="Mit einem Spritkostenrechner berechnest Du Deine Fahrtkosten und erfährst, wie viel Liter Benzin auf 100 km verbraucht wurden. Jetzt auf Tankchecker informieren!"
        image={fluid.src}
      />
      <Header />
      <main>
        <StaticContainer maxWidth="lg">
          <Spacer />
          <StaticHeadline variant="h1" align="center" gutterBottom>
            Benzinpreis-Rechner: Spritverbrauch einfach berechnen
          </StaticHeadline>
          <StaticImage
            fluid={fluid}
            alt="Benzinpreis-Rechner: Spritverbrauch einfach berechnen"
            copyright="Bildnachweis: AlexLMX / iStock"
          />
          <StaticParagraph gutterBottom>
            Manch einer möchte gerne wissen, wieviel Benzin er oder sie im
            Schnitt pro 100 Kilometer verbraucht. Da kann ein
            Benzinpreis-Rechner ein hilfreiches Tool sein. Heutzutage musst Du
            dafür keine komplizierten Berechnungen auf Papier mehr anstellen,
            das kann alles ein Spritkostenrechner für Dich übernehmen. Was genau
            möchtest Du ausrechnen? Mit einem Spritkostenrechner kannst Du
            sowohl ausrechnen, mit wie viel Euro an Fahrtkosten Du für Deine
            baldige Fahrt rechnen kannst. Aber Du kannst auch nachträglich
            beispielsweise den exakten Benzinverbrauch pro 100 Kilometer
            ausrechnen. Wie das geht, erfährst Du im Folgenden.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wie kann ich mit einem Fahrtkostenrechner den Benzinpreis berechnen?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Du bist beruflich oder privat demnächst unterwegs und hast
            vielleicht weitere Insassen im Auto und möchtest im Vorfeld gerne
            wissen, wie hoch die Fahrtkosten für jeden Mitfahrer ungefähr sind?
            Dann suche Dir einen Fahrtkostenrechner, der in der Regel folgende
            Informationen von Dir abfragt:
          </StaticParagraph>
          <StaticUl>
            <li>Die Entfernung zum Zielort in km</li>
            <li>Den Benzinverbrauch Deines Wagens auf 100 km</li>
            <li>Der Benzinpreis Deines Kraftstoffes pro Liter</li>
            <li>
              Ggf. noch Anzahl der Personen im Auto, wenn Du die Kosten teilen
              möchtest
            </li>
            <li>Ggf. anfallende Parkgebühren</li>
            <li>Ggf. anfallende Mautgebühren</li>
          </StaticUl>
          <StaticParagraph gutterBottom>
            Daraus errechnet der Fahrtkostenrechner Dir dann die
            voraussichtlichen Spritkosten pro Mitfahrer. Allerdings gibt es hier
            bei einem Faktor eine gewisse Ungenauigkeit. Denn der Spritverbrauch
            Deines Wagens auf 100 km kann unter Umständen deutlich von dem
            abweichen, was Dir der Autohändler angepriesen hat beim Kauf des
            Wagens. Die Benzin-Verbrauchswerte, die für ein Automodell vom
            Händler an Durchschnittswerten angegeben sind, sind eben genau das:
            Durchschnittswerte. Ermittelt unter unrealistischen Bedingungen und
            Testsituationen. In der Regel weichen die tatsächlichen Werte für
            den wirklichen Spritverbrauch deutlich davon ab. Daher ist es am
            besten, wenn Du Dir die Mühe machst, den Spritverbrauch Deines Autos
            einmal selbst zu ermitteln.
          </StaticParagraph>
          <StaticHeadline variant="h2" gutterBottom>
            Wie berechne ich meinen Spritverbrauch auf 100 km?
          </StaticHeadline>
          <StaticParagraph gutterBottom>
            Es lohnt sich für jeden Autofahrer, sich mithilfe eines
            Online-Rechners den genauen Verbrauch des eigenen Wagens einmal
            selbst auszurechnen. Die Hersteller-Werte beim Händler sahen so
            schön aus auf dem Papier, aber wenn da drei hübsch anzusehende
            Zahlen standen (in der Regel wird der Verbrauch pro 100 km
            außerorts, innerorts und gemittelt angegeben), heißt das noch lange
            nicht, dass Dein Wagen auch so viel verbraucht. Also so wenig
            verbraucht. Denn in der Realität sieht der Benzinverbrauch meist
            anders aus. Höher. Doch wie bekommst Du nun heraus, wieviel Benzin
            Dein Auto wirklich pro 100 km verbraucht? Dafür gibt es im Netz oder
            als App einige praktische Spritverbrauchsrechner.
          </StaticParagraph>
          <StaticParagraph gutterBottom>
            Und so gehst Du am besten vor, wenn Du Deinen Benzinverbrauch
            berechnen willst:
          </StaticParagraph>
          <StaticOl>
            <li>
              Stelle beim nächsten Volltanken Deinen Tageskilometerzähler auf
              Null und fahre dann die Strecke, die Dich am meisten interessiert
              (nur innerorts, nur außerhalb der Stadt auf Landstraßen oder
              Autobahn, oder beides gemischt).
            </li>
            <li>
              Beim nächsten Volltanken hebst Du den Bon auf, auf dem steht, wie
              viel Liter Du frisch getankt hast.
            </li>
            <li>
              Notiere Dir die gefahrenen Kilometer des Tageskilometerzählers.
            </li>
            <li>
              Nun teile die neu vollgetankten Liter durch die von Dir gefahrenen
              und beim Tageskilometermesser abgelesenen Kilometer. Anschließend
              mit 100 multiplizieren und das ergibt dann den tatsächlichen Wert
              für die verbrauchten Liter pro 100 Kilometer.
            </li>
          </StaticOl>

          <StaticParagraph gutterBottom>
            Beispiel: Du fährst 400 km sowohl in der Stadt als auch Autobahn.
            Beim nächsten Volltanken steht auf dem Bon, dass Du 22 Liter getankt
            hast.
          </StaticParagraph>

          <StaticParagraph gutterBottom>
            (22 Liter/400 km) x 100 = 5,5 Liter/100 km
          </StaticParagraph>

          <StaticParagraph gutterBottom>
            Damit hast Du einen guten Anhaltspunkt. Wichtig ist, dass Du immer
            volltankst, denn nur so kannst Du möglichst genau die Berechnung
            durchführen. Wenn Du es ganz genau wissen willst, führe diese
            Berechnung über einen längeren Zeitraum durch. Denn nicht immer
            fährst Du die gleichen Strecken oder beschleunigst und bremst gleich
            viel. Je mehr Werte Du hast, umso exakter wird Dein
            durchschnittlicher Verbrauchswert. Statt es selbst auszurechnen,
            kannst Du die Berechnung auch von einem Spritverbrauchsrechner
            durchführen lassen, der dann die Werte von Dir abfragt.
          </StaticParagraph>

          <StaticHeadline variant="h2" gutterBottom>
            Wie kann ich die Benzinkosten senken?
          </StaticHeadline>

          <StaticParagraph gutterBottom>
            Wenn Du besonders spritsparend fahren willst, dann gibt es einige
            Tipps, die Du berücksichtigen kannst, um den Spritverbrauch zu
            senken:
          </StaticParagraph>

          <StaticUl>
            <li>
              Vorsichtiger Gas geben: Für alle, die gerne schnell fahren und
              auch ein Fahrzeug haben, was ordentlich PS unter der Haube hat,
              leider ein kleiner Dämpfer. Aber je umsichtiger Du beschleunigst,
              umso weniger Benzin verbraucht Dein Wagen. Ist so, das kann man
              leider auch nicht schönreden. Also umsichtig anfahren an der
              Ampel, statt das Gaspedal voll durchzutreten. Je mehr Du das
              Gaspedal durchtrittst, umso höher wird Dein Spritverbrauch in dem
              Moment.
            </li>
            <li>
              Frühzeitig schalten: Wenn Du frühzeitig in einen höheren Gang
              schaltest, bleibt der Motor in einem niedrigeren Drehzahlbereich
              und das wiederum schon den Benzinverbrauch.
            </li>
            <li>
              Vorausschauend fahren: Wenn eine Baustelle angekündigt wird oder
              ein Hindernis vor Dir erscheint, gehe lieber früher vom Gas, statt
              zu bremsen. Im Stau lieber den Wagen langsam und konstant rollen
              lassen, falls das möglich ist, statt ständig komplett abzubremsen
              und wieder anzufahren. Jedes Anfahren kostet Dich wieder vermehrt
              Benzin.
            </li>
            <li>
              Unnötiges Gepäck ausladen: Du fährst seit Wochen drei Wasserkästen
              im Kofferraum spazieren? Besser sofort ausladen, denn je mehr
              unnötiges Gewicht Du umherfährst, umso mehr Benzin verbrauchst Du.
            </li>
            <li>
              Regelmäßige Wartungen: Gönne Deinem Wagen in regelmäßigen
              Abständen einen Ölwechsel und lasse Verschleißteile rechtzeitig
              austauschen. Ansonsten droht auch hier ein immer weiter
              ansteigender Spritverbrauch.
            </li>
          </StaticUl>
          <StaticParagraph gutterBottom>
            Du siehst, mit einer umsichtigen Fahrweise lassen sich die
            Benzinkosten durchaus kontrollieren. Und dank eines
            Benzinpreis-Rechners weißt Du demnächst auch vor Fahrtantritt,
            welche Spritkosten auf Dich und Deine Mitfahrer zukommen.
            Zusatztipp: Einige Spritpreis-Rechner empfehlen Dir auch die
            spritsparendste Route, wenn Du den Start- und Zielort angibst.
          </StaticParagraph>

          <Spacer />
          <Spacer />
        </StaticContainer>
      </main>
      <Footer />
    </Layout>
  )
}
